import { useEffect, useState, useRef, memo, useCallback } from 'react';

import { Slider, Stack, IconButton, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom';

import {
  useFolderAudioIdsLazyQuery,
  useResourceAudioIdsLazyQuery,
} from '../../graphql/resolver.types';
import Login from '../../landing/views/Login';
import { useScripts } from '../../layout/utils/LanguageHelper';
import NoResultFoundSvg from '../../layout/utils/NoResultFoundSvg';
import PauseIcon from '../../svg/PauseIcon';
import PlayIcon from '../../svg/PlayIcon';

// Interfaces
interface Audio {
  id: string;
  audioUrl: string;
  filename: string;
}

interface Video {
  id: string;
  key?: string | null;
  videoUrl: string;
  filename?: string | null;
  urlname?: string | null;
}

interface SimpleAudioPlayerProps {
  audioSrc: string;
  audioName: string;
  isScreenDownSm: boolean;
}

interface SimpleVideoPlayerProps {
  videoSrc: string;
  videoKey: string;
  videoFileName: string;
  videoUrlName: string;
  isScreenDownSm: boolean;
}

// Simple Audio Player Component
const SimpleAudioPlayer = memo(
  ({ audioSrc, audioName, isScreenDownSm }: SimpleAudioPlayerProps) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef<HTMLAudioElement>(null);

    // Handlers
    const handlePlayPause = useCallback(() => {
      const audio = audioRef.current;
      if (audio) {
        isPlaying ? audio.pause() : audio.play();
        setIsPlaying((prev) => !prev);
      }
    }, [isPlaying]);

    const handleSliderChange = useCallback(
      (event: Event, newValue: number | number[]) => {
        if (audioRef.current && typeof newValue === 'number') {
          audioRef.current.currentTime = newValue;
          setCurrentTime(newValue);
        }
      },
      [],
    );

    const formatTime = (seconds: number) => {
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    useEffect(() => {
      const audio = audioRef.current;
      if (!audio) return;

      const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
      const handleLoadedMetadata = () => setDuration(audio.duration);
      const handleEnded = () => setIsPlaying(false);

      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('ended', handleEnded);

      return () => {
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audio.removeEventListener('ended', handleEnded);
      };
    }, []);

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{
          padding: 2,
          borderRadius: '10px',
          backgroundColor: '#FFF',
          width: '100%',
          boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
          ':hover': {
            backgroundColor: '#FFF',
            border: '1px solid #08A590',
            color: '#08A590',
          },
          overflow: 'auto',
        }}
      >
        <Stack direction="row" alignItems="center">
          <IconButton onClick={handlePlayPause}>
            {isPlaying ? (
              <PauseIcon color="#08A590" style={{ transform: 'scale(1.5)' }} />
            ) : (
              <PlayIcon style={{ transform: 'scale(1.5)' }} />
            )}
          </IconButton>
          <Typography>{formatTime(currentTime)}</Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
          sx={{ width: '30%' }}
        >
          <Slider
            min={0}
            max={duration}
            value={currentTime}
            onChange={handleSliderChange}
            sx={{
              width: '100%',
              height: '4px',
              '& .MuiSlider-thumb': {
                width: '8px',
                height: '8px',
                '&.Mui-active': {
                  width: '20px',
                  height: '20px',
                },
              },
              color: '#00A591',
            }}
          />
        </Stack>

        <audio ref={audioRef} src={audioSrc} />
        <Typography
          sx={{
            textAlign: 'center',
            wordWrap: 'break-word',
            width: isScreenDownSm ? '100%' : '50%',
          }}
        >
          {audioName}
        </Typography>
      </Grid>
    );
  },
);
// Add display name for debugging purposes
SimpleAudioPlayer.displayName = 'SimpleAudioPlayer';

// Simple Video Player Component
const SimpleVideoPlayer = memo(
  ({
    videoSrc,
    videoKey,
    videoFileName,
    videoUrlName,
    isScreenDownSm,
  }: SimpleVideoPlayerProps) => {
    const videoURL = window.location.href.slice(
      window.location.href.indexOf('/media/videos'),
    );

    const aspectRatio = 9 / 16; // 16:9 比例
    const iframeWidth = 0.7; // 與視窗寬度的比例

    const [youtubeVideoId, setYoutubeVideoId] = useState('');
    const [iframeHeight, setIframeHeight] = useState(
      window.innerWidth * iframeWidth * aspectRatio,
    );
    const token = localStorage.getItem('token');
    const [videoWidth, setVideoWidth] = useState<number | null>(null);
    const [videoHeight, setVideoHeight] = useState<number | null>(null);

    // 監聽 video 元素的 loadedmetadata 事件，在影片加載後獲取影片的原始寬度和高度
    const handleVideoLoadedMetadata = (
      event: React.SyntheticEvent<HTMLVideoElement, Event>,
    ) => {
      const target = event.target as HTMLVideoElement;
      setVideoWidth(target.videoWidth);
      setVideoHeight(target.videoHeight);
    };

    useEffect(() => {
      const resizeListener = () =>
        setIframeHeight(window.innerWidth * iframeWidth * aspectRatio);
      window.addEventListener('resize', resizeListener);
      return () => window.removeEventListener('resize', resizeListener);
    }, []);

    // function extractYouTubeVideoId(url: string) {
    //   const match = url.match(
    //     /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
    //   );
    //   return match && match[1];
    // }

    // useEffect(() => {
    //   const youtubeVideoId = extractYouTubeVideoId(videoSrc) || '';
    //   setYoutubeVideoId(youtubeVideoId);
    // }, []);

    useEffect(() => {
      if (videoSrc) {
        const match = videoSrc.match(
          /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
        );
        setYoutubeVideoId(match ? match[1] : '');
      }
    }, [videoSrc]);

    if (!token) return <Login url={videoURL} />;

    return (
      <Grid container alignItems="center" justifyContent="center" mt="2em" width="100%">
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{
            width: '80%',
          }}
        >
          {videoKey ? (
            <div
              style={{
                maxWidth: '800px',
                aspectRatio: `${videoWidth || 16}/${videoHeight || 9}`,
                margin: '0 auto',
                position: 'relative',
              }}
            >
              <video
                controls
                controlsList="nodownload"
                onLoadedMetadata={handleVideoLoadedMetadata}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              >
                <source src={videoSrc} type="video/mp4" />
              </video>
            </div>
          ) : (
            <iframe
              width="100%"
              height={iframeHeight}
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              title={videoFileName || 'Video'}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          )}
          <Typography
            variant="h5"
            fontWeight={600}
            noWrap={true}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              textAlign: 'center',
              whiteSpace: 'pre-line',
              wordBreak: 'break-all',
            }}
            mt={2.5}
          >
            {videoFileName || videoUrlName}
          </Typography>
        </Grid>
      </Grid>
    );
  },
);
// Add display name for debugging purposes
SimpleVideoPlayer.displayName = 'SimpleVideoPlayer';

// MediaPage Component
const MediaPage = () => {
  const scripts = useScripts();
  const isScreenDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const mediaURL = window.location.href.slice(
    Math.max(
      window.location.href.indexOf('/media/audios'),
      window.location.href.indexOf('/media/videos'),
    ),
  );

  const { title = '', resourceId = '', parentFolderId = '', media = '' } = useParams();
  const token = localStorage.getItem('token');

  const [resourceAudioIdsQuery, { data, loading, error }] =
    useResourceAudioIdsLazyQuery();
  const [
    folderAudioIdsQuery,
    { data: folderAudiosData, loading: folderAudiosLoading, error: folderAudiosError },
  ] = useFolderAudioIdsLazyQuery();

  useEffect(() => {
    if (!token) return;
    if (parentFolderId) {
      folderAudioIdsQuery({ variables: { folderId: parentFolderId } });
    } else if (!parentFolderId && resourceId) {
      resourceAudioIdsQuery({ variables: { resourceId } });
    }
  }, [token, parentFolderId, resourceId]);

  const renderMedia = (items: Audio[] | Video[], Component: React.ElementType) =>
    items.map((item) => {
      if ('audioUrl' in item) {
        return (
          <Grid key={item.id} sx={{ width: isScreenDownSm ? '100%' : '50%' }}>
            <Component
              audioSrc={item.audioUrl}
              audioName={item.filename ?? ''}
              isScreenDownSm={isScreenDownSm}
            />
            <br />
          </Grid>
        );
      } else {
        return (
          <Grid key={item.id} sx={{ width: isScreenDownSm ? '100%' : '50%' }}>
            <Component
              videoSrc={item.videoUrl}
              videoKey={item.key ?? ''}
              videoFileName={item.filename ?? ''}
              videoUrlName={item.urlname ?? ''}
              isScreenDownSm={isScreenDownSm}
            />
            <br />
          </Grid>
        );
      }
    });

  if (!token) return <Login url={mediaURL} />;
  if (loading || folderAudiosLoading)
    return <Typography sx={{ textAlign: 'center' }}>{scripts.loading}</Typography>;
  if (error || folderAudiosError)
    return <Typography color="error">{scripts.errorLoadingAudios}</Typography>;

  const mediaItems = parentFolderId
    ? media === 'audios'
      ? folderAudiosData?.folder?.audioDetail?.audios
      : folderAudiosData?.folder?.videoDetail?.videos
    : media === 'audios'
    ? data?.resource?.audioDetail?.audios
    : data?.resource?.videoDetail?.videos;

  return (
    <Grid container alignItems="center" justifyContent="center" mt="2em" width="100%">
      <Grid container direction="column" alignItems="center" sx={{ width: '100%' }}>
        <Typography variant="h6">{title}</Typography>
        {mediaItems?.length ? (
          renderMedia(
            mediaItems,
            media === 'audios' ? SimpleAudioPlayer : SimpleVideoPlayer,
          )
        ) : (
          <NoResultFoundSvg />
        )}
      </Grid>
    </Grid>
  );
};
// Add display name for better DevTools debugging
MediaPage.displayName = 'MediaPage';

export default MediaPage;
